import React from "react";
import Layout from "../components/Layout";
// import Modal from "../components/Modal";
// import Gift from "../components/Gift";
import { Link } from "gatsby";
// import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
// import Verdura from "../components/Verdura";
// import Mix from "../components/Mix";
// import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
// import Trustpilot from "../components/Trustpilot";
// import RecensioneIsabella from "../components/RecensioneIsabella";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
// import RecensioneSara from "../components/RecensioneSara";
// import HeroImage from "../components/HeroImage";
// import Arance from "../components/Arance";
// import Limoni from "../components/Limoni";
import BigLogo from "../components/BigLogo";
// import ReactPlayer from "react-player/file";

export default () => {
    // const modalRef1 = useRef();

    return (
        <Layout index>
            <main className="flex flex-grow flex-col justify-center py-2 px-4 md:px-32 mt-8 md:mt-0">
                <section className="flex flex-wrap items-center text-center justify-center">
                    <div className="md:mx-auto max-w-lg">
                        <div className="text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <BigLogo />
                            {/* </div> */}
                        </div>
                        <div className="mt-8 text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                // onClick={() => modalRef1.current.openModal()}
                                className="w-full bg-white hover:shadow-xl text-black py-2 px-4 border border-black"
                            >
                                <Link to="/italiano">
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Italiano
                                    </h2>
                                </Link>
                            </button>
                            {/* </div> */}
                        </div>
                        <div className="mt-8 text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button
                                // onClick={() => modalRef1.current.openModal()}
                                className="w-full bg-white hover:shadow-xl text-black py-2 px-4 border border-black"
                            >
                                <Link to="/international">
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        International
                                    </h2>
                                </Link>
                            </button>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* <div
                        className="w-full max-w-2xl mt-5 mx-auto md:mr-0 md:ml-auto md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <HeroImage />
                    </div> */}
                </section>
            </main>
        </Layout>
    );
};
